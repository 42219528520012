export interface Artifact {
  artifactVersion: string;
  deviceId: string;
  fileName: string;
  url: string;
  fields: Record<string, string>;
  artifactId: string;
  itemNumbers: string[];
  productFamily: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  fileLength: number;
  downloadGroups: string[];
  editGroups: string[];
}

export interface ArtifactResult {
  result: Artifact[];
}
